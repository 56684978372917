import React, { useEffect, useState, useCallback } from 'react';
import { Button, Collapse, Row, Col, Spinner } from 'react-bootstrap';
import {
  getRewardsOfItem,
  deletePromoShopItem,
  getRewardedBoxes,
  getBoxesByItem,
} from '../../../apis/atlantis.api';
import EditShopItemModal from './ShopItemModal';
import ExportCSVButton from '../../shared-components/ExportButton';

const RewardItem = ({ reward, token }) => {
  const [rewardedBoxes, setRewardedBoxes] = useState();
  const [loading, setLoading] = useState(false);

  const handleGetRewardedBoxes = async () => {
    setLoading(true);
    try {
      const payload = await getRewardedBoxes(reward.promoId, reward.rewardId, token);
      setRewardedBoxes(payload);
    } catch (error) {
      console.error('Error fetching rewarded boxes:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="card border-0 shadow-sm rounded-4 mb-3">
      <div className="card-body p-3">
        <Row className="align-items-center">
          <Col xs={12} md={3} className="text-center">
            <div className="p-2 bg-light rounded-3 d-inline-block mb-2 mb-md-0">
              <img
                src={reward.imageUrl}
                alt={reward.name}
                style={{
                  width: '100%',
                  maxWidth: '80px',
                  maxHeight: '80px',
                  objectFit: 'contain',
                }}
                className="rounded-2"
              />
            </div>
          </Col>
          <Col xs={12} md={6}>
            <h6 className="fw-semibold mb-2">{reward.name}</h6>
            <div className="d-flex flex-wrap gap-2 mb-2">
              <span className="badge bg-light text-dark rounded-pill px-3 py-2">
                {reward.rewardType}
              </span>
              <span className="badge bg-success-subtle text-success rounded-pill px-3 py-2">
                {(reward.pct * 100).toFixed(2)}% chance
              </span>
            </div>
            {reward.rewardType === 'SOL' && (
              <p className="text-muted mb-0" style={{ fontSize: '0.875rem' }}>
                <i className="fa fa-coins me-1"></i>
                {reward.solAmount} SOL
              </p>
            )}
            {reward.rewardType === 'USDC' && (
              <p className="text-muted mb-0" style={{ fontSize: '0.875rem' }}>
                <i className="fa fa-coins me-1"></i>
                {reward.usdcAmount} USDC
              </p>
            )}
            {reward.rewardType === 'NFT' && (
              <p className="text-muted mb-0" style={{ fontSize: '0.875rem' }}>
                <i className="fa fa-image me-1"></i>
                Quantity: {reward.quantity}
              </p>
            )}
          </Col>
          <Col xs={12} md={3} className="text-center mt-3 mt-md-0">
            {!rewardedBoxes?.length ? (
              <Button
                variant="outline-primary"
                onClick={handleGetRewardedBoxes}
                className="rounded-3 px-3 py-2"
                disabled={loading}
                style={{
                  fontSize: '0.875rem',
                  borderColor: '#6366f1',
                  color: '#6366f1',
                }}
              >
                {loading ? (
                  <>
                    <Spinner animation="border" size="sm" role="status" className="me-2" />
                    <span>Loading...</span>
                  </>
                ) : (
                  <>
                    <i className="fa fa-cloud-download-alt me-2"></i>
                    Load Data
                  </>
                )}
              </Button>
            ) : (
              <div className="d-flex flex-column align-items-center">
                <div className="text-muted mb-2" style={{ fontSize: '0.875rem' }}>
                  {rewardedBoxes.length} boxes rewarded
                </div>
                <ExportCSVButton
                  data={rewardedBoxes}
                  number={rewardedBoxes?.length}
                  className="btn btn-sm rounded-3 px-3 py-2"
                  style={{
                    backgroundColor: '#6366f1',
                    borderColor: '#6366f1',
                    boxShadow: '0 2px 4px rgba(99, 102, 241, 0.25)',
                    fontSize: '0.875rem',
                  }}
                />
              </div>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

const ExpandableShopItem = ({ shopItem, token, refreshShopItems }) => {
  const [showModal, setShowModal] = useState(false);
  const [rewards, setRewards] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [purchasedBoxes, setPurchasedBoxes] = useState();

  const handleGetPurchasedBoxesByItem = async () => {
    setLoading(true);
    try {
      const payload = await getBoxesByItem(shopItem.promoId, shopItem.itemId, token);
      setPurchasedBoxes(payload);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchRewards = useCallback(async () => {
    try {
      const rewardsList = await getRewardsOfItem(shopItem.promoId, shopItem.itemId, token);
      setRewards(rewardsList);
    } catch (error) {
      console.error('Error fetching rewards:', error);
    }
  }, [shopItem.promoId, shopItem.itemId, token]);

  useEffect(() => {
    fetchRewards();
  }, [fetchRewards]);

  const handleClose = async () => {
    setShowModal(false);
  };

  const handleShow = () => setShowModal(true);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const deleteShopItem = async item => {
    if (window.confirm(`Are you sure you want to delete ${item.name}?`)) {
      try {
        setLoading(true);
        await deletePromoShopItem(item.promoId, item.itemId, token);
        refreshShopItems();
      } catch (error) {
        console.error('Error deleting shop item:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="shop-item mb-4">
      <div className="card border-0 shadow-sm rounded-4 overflow-hidden">
        <div className="card-body p-0">
          <div className="p-4">
            <Row className="align-items-center">
              <Col xs={12} md={3} className="text-center mb-3 mb-md-0">
                <div
                  className="p-3 bg-light rounded-4 d-inline-flex align-items-center justify-content-center"
                  style={{ width: '140px', height: '140px' }}
                >
                  <img
                    src={shopItem.imageUrl}
                    alt={shopItem.name}
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      objectFit: 'contain',
                    }}
                    className="rounded-3"
                  />
                </div>
              </Col>
              <Col xs={12} md={6} className="mb-3 mb-md-0">
                <h5 className="fw-semibold mb-2">{shopItem.name}</h5>
                <p className="text-muted mb-3" style={{ fontSize: '0.95rem' }}>
                  {shopItem.description.slice(0, 100)}
                  {shopItem.description.length > 100 && '...'}
                </p>
                <div className="d-flex flex-wrap gap-2 mb-2">
                  <span className="badge bg-primary-subtle text-primary rounded-pill px-3 py-2">
                    <i className="fa fa-tag me-1"></i>
                    {shopItem.cost} {shopItem.currency}
                  </span>
                  {shopItem.ungiftable ? (
                    <span className="badge bg-secondary-subtle text-secondary rounded-pill px-3 py-2">
                      <i className="fa fa-box me-1"></i>
                      Direct Item
                    </span>
                  ) : (
                    <span className="badge bg-info-subtle text-info rounded-pill px-3 py-2">
                      <i className="fa fa-box-open me-1"></i>
                      Has Rewards
                    </span>
                  )}
                </div>
              </Col>
              <Col xs={12} md={3}>
                <div className="d-flex flex-wrap gap-2 justify-content-md-end">
                  <Button
                    variant="outline-primary"
                    onClick={handleShow}
                    className="rounded-3 px-3 py-2"
                    style={{
                      fontSize: '0.875rem',
                      borderColor: '#6366f1',
                      color: '#6366f1',
                    }}
                  >
                    <i className="fa fa-edit me-1"></i>
                    Edit
                  </Button>

                  {!purchasedBoxes?.length ? (
                    <Button
                      variant="outline-success"
                      onClick={handleGetPurchasedBoxesByItem}
                      className="rounded-3 px-3 py-2"
                      disabled={loading}
                      style={{
                        fontSize: '0.875rem',
                      }}
                    >
                      {loading ? (
                        <Spinner animation="border" size="sm" role="status" />
                      ) : (
                        <>
                          <i className="fa fa-download me-1"></i>Load
                        </>
                      )}
                    </Button>
                  ) : (
                    <ExportCSVButton
                      className="btn btn-success rounded-3 px-3 py-2"
                      data={purchasedBoxes}
                      number={purchasedBoxes?.length}
                      style={{
                        fontSize: '0.875rem',
                      }}
                    />
                  )}

                  <Button
                    variant="outline-danger"
                    onClick={() => deleteShopItem(shopItem)}
                    className="rounded-3 px-3 py-2"
                    disabled={loading}
                    style={{
                      fontSize: '0.875rem',
                    }}
                  >
                    {loading ? (
                      <Spinner animation="border" size="sm" role="status" />
                    ) : (
                      <>
                        <i className="fa fa-trash-alt me-1"></i>Delete
                      </>
                    )}
                  </Button>

                  <Button
                    variant={expanded ? 'primary' : 'outline-secondary'}
                    onClick={toggleExpanded}
                    className="rounded-3 px-3 py-2"
                    aria-expanded={expanded}
                    style={{
                      fontSize: '0.875rem',
                      ...(expanded
                        ? {
                            backgroundColor: '#6366f1',
                            borderColor: '#6366f1',
                          }
                        : {}),
                    }}
                  >
                    <i className={`fa fa-chevron-${expanded ? 'up' : 'down'} me-1`}></i>
                    {expanded ? 'Collapse' : 'Rewards'}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>

          <Collapse in={expanded}>
            <div>
              <div className="border-top pt-3 px-4 pb-4 bg-light">
                <h6 className="fw-semibold mb-3 text-muted">
                  <i className="fa fa-gift me-2"></i>
                  Reward Items ({rewards.length})
                </h6>
                {rewards.length > 0 ? (
                  rewards.map((reward, index) => (
                    <RewardItem reward={reward} key={index} token={token} />
                  ))
                ) : (
                  <div className="text-center py-4 text-muted">
                    <i className="fa fa-inbox fa-2x d-block mb-2"></i>
                    <p>No rewards found for this item</p>
                  </div>
                )}
              </div>
            </div>
          </Collapse>
        </div>
      </div>

      <EditShopItemModal
        open={showModal}
        handleClose={handleClose}
        fetchRewards={fetchRewards}
        shopItem={shopItem}
        rewards={rewards}
        token={token}
      />
    </div>
  );
};

export default ExpandableShopItem;
