import React, { useState, useEffect } from 'react';

const AddShopItemForm = ({ onAdd }) => {
  const [formValues, setFormValues] = useState({
    name: '',
    description: '',
    cost: 0,
    upperLimit: 0,
    dailyPurchaseLimit: 0,
    imageUrl: '',
    currency: 'FRAGMENTS',
    ungiftable: false,
  });

  // Preview state for image
  const [imagePreview, setImagePreview] = useState('');

  // Update image preview when imageUrl changes
  useEffect(() => {
    if (formValues.imageUrl) {
      setImagePreview(formValues.imageUrl);
    } else {
      setImagePreview('');
    }
  }, [formValues.imageUrl]);

  const handleChange = event => {
    const { name, value } = event.target;
    setFormValues(prevValues => ({
      ...prevValues,
      [name]:
        name === 'cost' || name === 'upperLimit' || name === 'dailyPurchaseLimit'
          ? isNaN(parseFloat(value))
            ? 0
            : parseFloat(value)
          : value,
    }));
  };

  const handleChangeUngiftable = () => {
    setFormValues(prevValues => ({
      ...prevValues,
      ungiftable: !formValues.ungiftable,
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();
    // Set discount cost equal to cost before submitting
    const submitValues = {
      ...formValues,
      ogCost: formValues.cost, // Keep ogCost for API compatibility
    };
    onAdd(submitValues);
  };

  // Helper function to render form fields
  const renderFormField = (id, label, type = 'text', required = false) => (
    <div className="mb-4">
      <label
        htmlFor={id}
        className="form-label text-muted fw-medium mb-2"
        style={{ fontSize: '0.875rem' }}
      >
        {label}
        {required && <span className="text-danger ms-1">*</span>}
      </label>
      <input
        type={type}
        className="form-control form-control-lg shadow-none border-light-subtle rounded-3"
        id={id}
        name={id}
        value={formValues[id]}
        onChange={handleChange}
        required={required}
        style={{
          padding: '0.75rem 1rem',
          fontSize: '1rem',
          transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
        }}
      />
    </div>
  );

  return (
    <form onSubmit={handleSubmit} className="bg-white p-4 rounded-4 shadow-sm">
      <h5 className="mb-4 fw-semibold text-dark">Add New Shop Item</h5>

      <div className="row g-4">
        <div className="col-md-6">
          <div className="card border-0 shadow-sm rounded-4 mb-4">
            <div className="card-body">
              <h6 className="card-subtitle mb-3 text-muted fw-medium">Basic Information</h6>
              {renderFormField('name', 'Name', 'text', true)}

              <div className="mb-4">
                <label
                  htmlFor="description"
                  className="form-label text-muted fw-medium mb-2"
                  style={{ fontSize: '0.875rem' }}
                >
                  Description<span className="text-danger ms-1">*</span>
                </label>
                <textarea
                  className="form-control shadow-none border-light-subtle rounded-3"
                  id="description"
                  name="description"
                  value={formValues.description}
                  onChange={handleChange}
                  rows="4"
                  required
                  style={{
                    padding: '0.75rem 1rem',
                    fontSize: '1rem',
                    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                  }}
                />
              </div>
            </div>
          </div>

          <div className="card border-0 shadow-sm rounded-4 mb-4">
            <div className="card-body">
              <h6 className="card-subtitle mb-3 text-muted fw-medium">Item Type</h6>
              <div className="mb-4">
                <label
                  htmlFor="ungiftable"
                  className="form-label text-muted fw-medium mb-2"
                  style={{ fontSize: '0.875rem' }}
                >
                  Item Behavior
                </label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    name="ungiftable"
                    id="ungiftable"
                    checked={formValues.ungiftable === true}
                    onChange={handleChangeUngiftable}
                    style={{ width: '2.5em', height: '1.25em' }}
                  />
                  <label className="form-check-label" htmlFor="ungiftable">
                    &nbsp;Direct Item (No Rewards)
                  </label>
                </div>
                <small className="form-text text-muted" style={{ fontSize: '0.75rem' }}>
                  Toggle ON if this is a direct item that cannot be opened. Toggle OFF if this is a
                  box that reveals rewards when opened.
                </small>
              </div>
            </div>
          </div>

          <div className="card border-0 shadow-sm rounded-4 mb-4">
            <div className="card-body">
              <h6 className="card-subtitle mb-3 text-muted fw-medium">Pricing</h6>
              {renderFormField('cost', 'Cost (in Fragments)', 'number', true)}
              <small className="form-text text-muted" style={{ fontSize: '0.75rem' }}>
                The cost in Fragments currency that users will pay for this item.
              </small>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="card border-0 shadow-sm rounded-4 mb-4">
            <div className="card-body">
              <h6 className="card-subtitle mb-3 text-muted fw-medium">Item Image</h6>
              <div className="mb-4">
                <label
                  htmlFor="imageUrl"
                  className="form-label text-muted fw-medium mb-2"
                  style={{ fontSize: '0.875rem' }}
                >
                  Image URL<span className="text-danger ms-1">*</span>
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg shadow-none border-light-subtle rounded-3"
                  id="imageUrl"
                  name="imageUrl"
                  value={formValues.imageUrl}
                  onChange={handleChange}
                  required
                  style={{
                    padding: '0.75rem 1rem',
                    fontSize: '1rem',
                    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                  }}
                />
              </div>

              {imagePreview && (
                <div className="mt-3 p-3 bg-light rounded-3 text-center">
                  <img
                    src={imagePreview}
                    alt="Item Preview"
                    className="img-fluid rounded-3 shadow-sm"
                    style={{ maxHeight: '250px', objectFit: 'contain' }}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="card border-0 shadow-sm rounded-4">
            <div className="card-body">
              <h6 className="card-subtitle mb-3 text-muted fw-medium">Inventory Settings</h6>
              <div className="row g-3">
                <div className="col-md-6">
                  {renderFormField('upperLimit', 'Total Inventory', 'number', false)}
                  <small className="form-text text-muted" style={{ fontSize: '0.75rem' }}>
                    Maximum number of items available (0 = unlimited)
                  </small>
                </div>
                <div className="col-md-6">
                  {renderFormField('dailyPurchaseLimit', 'Daily Purchase Limit', 'number', false)}
                  <small className="form-text text-muted" style={{ fontSize: '0.75rem' }}>
                    Maximum purchases per user every 24 hours (0 = unlimited)
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end mt-4 pt-3 border-top">
        <button
          type="submit"
          className="btn btn-primary rounded-3 px-4 py-2"
          style={{
            fontSize: '0.95rem',
            backgroundColor: '#6366f1',
            borderColor: '#6366f1',
            boxShadow: '0 2px 4px rgba(99, 102, 241, 0.25)',
          }}
        >
          ADD ITEM
        </button>
      </div>
    </form>
  );
};

export default AddShopItemForm;
